import mime from 'mime/lite';
import useApi from './useApi';

export default () => {
  const uploadFileProgress = ref(0);

  const isFileValid = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const hasNoXSS = !event.target.result.includes('<script');

        if (!hasNoXSS) {
          console.error('File contains XSS', event.target.result);
        }

        resolve(hasNoXSS);
      };

      reader.onerror = (error) => {
        console.error('Error reading file:', error);
        reject(error);
      };

      reader.readAsText(file);
    });
  };

  const uploadFile = async({ url = '/s3/upload', file, fileName }) => {
    if (!file) {
      useToast.error(useT('abc336'));
      return false;
    }

    try {
      uploadFileProgress.value = 0;

      const formData = new FormData();
      formData.append('file', file);

      const isValid = await isFileValid(file);

      if (!isValid) {
        useToast.error(useT('abc335'));
        return false;
      }

      const response = await useApi.post(url, {
        body: {
          contentType: file.type || mime.getType(file.name),
          size: file.size || 0,
          key: fileName || file.name,
        },
      }).catch((error) => {
        console.error(error);
      });

      if (!response) {
        throw new Error('Uploading failed - no response');
      }

      const body = new FormData();
      for (const name in response.fields) {
        body.append(name, response.fields[name]);
      }
      body.append('file', file);

      const responseS3 = await $fetch.raw(response.url, {
        method: 'POST',
        body,
      }).catch((error) => {
        console.error(error);
        return false;
      });

      const location = responseS3?.headers?.get('location');

      if (!location) {
        throw new Error('Uploading failed - missing location');
      }

      return decodeURIComponent(location.replace('http://', 'https://'));
    } catch (error) {
      console.error(error);

      useToast.error(useT('abc335'));
      return false;
    }
  };

  return {
    uploadFile,
    uploadFileProgress,
  };
};
